














import SendCommentActionModel from "./SendCommentActionModel";
import PostActionMessageSetup from "../components/PostActionMessage/PostActionMessageSetup.vue";

import { MessageEditorWithMediaTab } from "piramis-base-components/src/components/MessageEditorWithMedia/types";

import { Component, VModel, Prop } from 'vue-property-decorator';
import { ValidationObserver } from "vee-validate";
import Vue from "vue";

@Component({
  data() {
    return {
      MessageEditorWithMediaTab
    }
  },
  components: {
    PostActionMessageSetup,
    ValidationObserver
  }
})
export default class SendCommentActionView extends Vue {
  @VModel() model!: SendCommentActionModel

  @Prop() disabled!: boolean
}
